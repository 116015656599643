import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useRecoilValue, useResetRecoilState } from "@geome/recoil"
import React, { ReactElement } from "react"
import { BPFuelCardsLocation } from "../../types"
import { CloseIcon } from "../icons/close"
import { selectedLocationIdAtom } from "@geome/recoil/dist/feature/locations/atoms/index"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { useReselectFromHistory } from "@geome/recoil/dist/feature/selection/hooks"
import { useSubscribeToUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { useSelectedLocation } from "@geome/recoil/dist/feature/locations/hooks"
import { OpenNow } from "@geome/react-components-next/lib/components/openNow"
import { HoursSection } from "./hoursSection"
import { PropertiesSection } from "@geome/react-components-next/lib/components/properties"
import { useConfig } from "@geome/react-next"
import { Link } from "@geome/react-components-next/lib/components/link"
import { CopyLinkButton } from "./copyLinkButton"
import { useDirectionsToLocation } from "@geome/recoil/dist/feature/directions/hooks"
import { appModeAtom } from "../../recoil/atoms"
import { useChangeAppMode } from "../../hooks/useChangeAppMode"

export const InfoWindow = (): ReactElement | null => {
  const { translate } = useI18n()
  const baseUrl = useConfig<string>("baseUrl", false)
  const appMode = useRecoilValue(appModeAtom)
  const changeAppMode = useChangeAppMode()
  const getDirectionsToLocation = useDirectionsToLocation()
  const selectedLocation = useSelectedLocation<BPFuelCardsLocation>()
  const enabledSections = useConfig<string[]>("info_window.enabled_sections", false)

  const closeInfoWindow = useResetRecoilState(selectedLocationIdAtom)
  const returnToLastGooglePlace = useReselectFromHistory({ type: "google_place" })
  const closeAndReturnToLastGooglePlace = (): void => {
    closeInfoWindow()
    returnToLastGooglePlace()
  }

  useSubscribeToUserInteraction(
    "*,-*:location:select,-*:completion:select,-*:telephone-link:*,-*:directions-link:*,-*:view-on-google-maps-link:*",
    closeAndReturnToLastGooglePlace
  )

  if (!selectedLocation) return null

  const {
    name,
    formatted_address,
    open_status,
    next_open_status_change,
    driving_distance,
    distance,
    telephone,
  } = selectedLocation

  const displayDistance = driving_distance ?? distance ?? null

  const onDirectionsClick = (): void => {
    getDirectionsToLocation(selectedLocation)
    if (appMode !== "directions") changeAppMode("directions")
  }

  return (
    <BlockScopingContextProvider block="info-window">
      <div className="info-window__wrapper">
        <div className="info-window">
          <div className="info-window__title">
            <h1 className="info-window__title-name">{name}</h1>
            <ButtonWithIcon
              className="info-window__close-button"
              text={translate("info_window.close") as string}
              textPosition="tooltip"
              icon={<CloseIcon />}
              onClick={closeAndReturnToLastGooglePlace}
              name="close-button"
            />
          </div>
          <div className="info-window__info">
            <div className="info-window__info-list">
              <div>{formatted_address}</div>
              {telephone && (
                <Link
                  className="info-window__link"
                  name="telephone-link"
                  href={`tel:${telephone}`}
                  target="_blank"
                >
                  {telephone}
                </Link>
              )}
              {displayDistance && (
                <div className="info-window__distance">
                  {translate("distance", {
                    convert: true,
                    distance: displayDistance,
                  })}
                </div>
              )}
            </div>
            <div className="info-window__item-open-now">
              {open_status && (
                <OpenNow
                  openStatus={open_status}
                  nextOpenStatusChange={next_open_status_change || null}
                  configPath=""
                />
              )}
            </div>
          </div>

          {Boolean(selectedLocation.streetview) && (
            <img
              src={`${baseUrl}/locations/${selectedLocation.id}/static_streetview/480x200`}
              alt={translate("info_window.streetview") as string}
            />
          )}

          <div className="info-window__links">
            <button className="info-window__link-button" onClick={onDirectionsClick}>
              {translate("info_window.add_to_route")}
            </button>
            <CopyLinkButton id={selectedLocation.id} />
          </div>
          <HoursSection location={selectedLocation} />

          {enabledSections.map((sectionName) => (
            <PropertiesSection
              key={sectionName}
              configPath="info_window."
              sectionName={sectionName}
              renderNullIfEmpty
              countryCode={selectedLocation.country_code}
              location={selectedLocation}
            />
          ))}
        </div>
      </div>
    </BlockScopingContextProvider>
  )
}
