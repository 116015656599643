import { useCSSCustomProperty } from "@geome/react-next/lib/hooks/useCSSCustomProperty"
import React, { ReactElement } from "react"

export const CloseIcon = (): ReactElement => {
  const fill = useCSSCustomProperty("--bp-grey-500", "#666666")

  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49584 7.50001L0.777582 14.2183C0.744484 14.2514 0.718228 14.2907 0.700315 14.3339C0.682403 14.3772 0.673182 14.4235 0.673182 14.4703C0.673182 14.5171 0.682403 14.5635 0.700315 14.6067C0.718228 14.65 0.744484 14.6893 0.777582 14.7224C0.810681 14.7555 0.849975 14.7817 0.89322 14.7996C0.936465 14.8175 0.982816 14.8268 1.02962 14.8268C1.07643 14.8268 1.12278 14.8175 1.16603 14.7996C1.20927 14.7817 1.24857 14.7555 1.28167 14.7224L7.99992 8.0041L14.7182 14.7224C14.785 14.7892 14.8757 14.8268 14.9702 14.8268C15.0648 14.8268 15.1554 14.7892 15.2223 14.7224C15.2891 14.6555 15.3267 14.5648 15.3267 14.4703C15.3267 14.3758 15.2891 14.2851 15.2223 14.2183L8.50401 7.50001L15.2223 0.781758C15.2554 0.748659 15.2816 0.709366 15.2995 0.666121C15.3174 0.622874 15.3267 0.576525 15.3267 0.529716C15.3267 0.482907 15.3174 0.436557 15.2995 0.393312C15.2816 0.350066 15.2554 0.310773 15.2223 0.277674C15.1892 0.244576 15.1499 0.21832 15.1066 0.200407C15.0634 0.182494 15.017 0.173274 14.9702 0.173274C14.9234 0.173274 14.8771 0.182494 14.8338 0.200407C14.7906 0.21832 14.7513 0.244576 14.7182 0.277674L7.99992 6.99593L1.28167 0.277674C1.21482 0.210828 1.12416 0.173274 1.02962 0.173274C0.93509 0.173274 0.844428 0.210828 0.777582 0.277674C0.710736 0.34452 0.673182 0.435182 0.673182 0.529716C0.673182 0.62425 0.710736 0.714912 0.777582 0.781758L7.49584 7.50001Z"
        fill={fill}
      />
    </svg>
  )
}
