import React, { ReactElement } from "react"
import { OpeningHours } from "@geome/react-components-next/lib/components/openingHours"
import { BPFuelCardsLocation } from "../../types"

type HoursSectionProps = {
  location: BPFuelCardsLocation
}

export const HoursSection = ({ location }: HoursSectionProps): ReactElement | null => {
  const { opening_hours } = location

  if (opening_hours !== undefined && opening_hours.length > 0) {
    return (
      <OpeningHours
        sectionName="hours"
        configPath="info_window."
        tables={[{ daysAndTimes: opening_hours }]}
        sectionProps={{ collapsible: false }}
      />
    )
  }

  return null
}
