import { useUIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import React, { ReactElement, useCallback } from "react"
import { ScrollWithHeaderFooter } from "@geome/react-components-next/lib/components/scrollWithHeaderFooter"
import { FilterGroups } from "@geome/react-components-next/lib/components/filters/filterGroups"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import { CloseIcon } from "../icons/close"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { useSubscribeToUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"

import { getImageForProperty } from "../icons/properties"

export const Filters = (): ReactElement => {
  const { toggle, groups } = useUIFilters()
  const { setCurrentModal } = useModalState()
  const { translate } = useI18n()

  const closeFilters = useCallback(async () => setCurrentModal(null), [setCurrentModal])
  useSubscribeToUserInteraction("*,-filters:*:*,-*:toggle-modal-button:*", closeFilters)

  return (
    <BlockScopingContextProvider block="filters">
      <ScrollWithHeaderFooter
        className="filters"
        renderHeader={() => (
          <div className="filters__title-bar">
            {translate("filters.title")}
            <ButtonWithIcon
              className="filters__close-button"
              text={translate("filters.close") as string}
              textPosition="tooltip"
              icon={<CloseIcon />}
              onClick={closeFilters}
              name="close-button"
            />
          </div>
        )}
      >
        <FilterGroups
          groups={groups}
          onToggleFilter={toggle}
          filterIcons={(filter) => (
            <img role="presentation" alt="" src={getImageForProperty(filter)}></img>
          )}
        />
      </ScrollWithHeaderFooter>
    </BlockScopingContextProvider>
  )
}
