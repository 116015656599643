import React, { ReactElement, Suspense, useEffect, useRef } from "react"
import { useBEM } from "@geome/react-next/lib/hooks/useBEM"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { useSidebarAdjustedUIPadding } from "../../../hooks/useSidebarAdjustedUIPadding"
import useResizeObserver from "use-resize-observer"
import { Search } from "../../search"
import { Filters } from "../../filters"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import { BPFuelCardsLocatorModal } from "../../../types"
import { useViewSwitcher } from "./useViewSwitcher"
import { LocationsList } from "../../locationsList"
import { InfoWindow } from "../../infoWindow"
import { usePreviousValue } from "@geome/react-next/lib/hooks/usePreviousValue"
import { useRecoilValue } from "@geome/recoil"
import { appModeAtom } from "../../../recoil/atoms"
import { DirectionsSearch } from "../../directions"
import { Itinerary } from "../../directions/itinerary"
import { LoadingBoxes } from "../loadingBoxes"
import { RouteOptions } from "../../routeOptions"

export const AppSideBar = (): ReactElement => {
  const isMobile = useIsMobile()
  const appMode = useRecoilValue(appModeAtom)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const sidebarContentRef = useRef<HTMLDivElement | null>(null)

  const { showList, showInfoWindow, showFilters, showItinerary, showRouteOptions } =
    useViewSwitcher()

  const previousShowFilters = usePreviousValue(showFilters)

  useResizeObserver({
    ref: sidebarContentRef,
    onResize: (size) => {
      if (!sidebarContentRef.current) return
      sidebarContentRef.current.style.setProperty("--sidebar-width", `${size.width}px`)
    },
  })

  const { currentModal } = useModalState<BPFuelCardsLocatorModal>()

  useSidebarAdjustedUIPadding({ sidebarRef, sidebarContentRef })

  // Can replace `has-content` with :has(.app__sidebar-content:not(:empty) once that's a thing
  const className = useBEM({
    block: "app",
    element: "sidebar",
    modifiers: {
      mobile: isMobile,
      "has-content": showFilters || showInfoWindow || showList || showItinerary || showRouteOptions,
      "show-list": showList && !showInfoWindow && !showFilters,
      "show-itinerary": showItinerary && !showInfoWindow && !showFilters,
    },
  })

  useEffect(() => {
    const filtersIsStillOpen = previousShowFilters === true && showFilters
    if (sidebarContentRef.current && !filtersIsStillOpen)
      sidebarContentRef.current.scrollTo({ top: 0 })
  }, [showList, showInfoWindow, showFilters, showItinerary, previousShowFilters])

  return (
    <div className={className} ref={sidebarRef}>
      {appMode === "search" ? <Search /> : <DirectionsSearch />}
      <div className="app__sidebar-content" ref={sidebarContentRef}>
        {showList && <LocationsList />}
        {showItinerary && (
          <Suspense fallback={<LoadingBoxes />}>
            <Itinerary />
          </Suspense>
        )}
        {showInfoWindow && (
          <Suspense fallback={<div className="info-window"></div>}>
            <InfoWindow />
          </Suspense>
        )}
        {currentModal?.name === "Filters" && <Filters />}
        {showRouteOptions && <RouteOptions />}
      </div>
    </div>
  )
}
