import React from "react"
import { ReactElement } from "react"

export const FiltersIcon = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="28" height="25" viewBox="0 0 28 25">
    <path
      fill="#666"
      d="M26.84 12.36h-2.17a3.88 3.88 0 0 0-7.67.01H.68a.61.61 0 0 0-.61.62.6.6 0 0 0 .61.6h16.34a3.88 3.88 0 0 0 7.67 0h2.16a.61.61 0 0 0 .6-.62.6.6 0 0 0-.6-.6Zm-6 3.27A2.67 2.67 0 0 1 18.39 14a2.63 2.63 0 0 1 1.93-3.6 2.67 2.67 0 0 1 3.18 2.59 2.64 2.64 0 0 1-2.66 2.64ZM.67 5.35h2.15a3.88 3.88 0 0 0 7.68.11l16.34.01a.61.61 0 0 0 .6-.61.6.6 0 0 0-.6-.61L10.5 4.24a3.88 3.88 0 0 0-7.65-.11H.67a.61.61 0 0 0-.6.6.6.6 0 0 0 .6.62Zm6-3.14a2.67 2.67 0 0 1 2.45 1.63 2.63 2.63 0 0 1-1.93 3.6A2.67 2.67 0 0 1 4 4.85a2.64 2.64 0 0 1 2.66-2.64Zm20.17 18.3H10.5a3.88 3.88 0 0 0-7.67.01H.67a.61.61 0 0 0-.6.61.6.6 0 0 0 .6.61h2.17a3.88 3.88 0 0 0 7.66 0h16.34a.61.61 0 0 0 .6-.62.6.6 0 0 0-.6-.61ZM6.67 23.76a2.67 2.67 0 0 1-2.46-1.63 2.63 2.63 0 0 1 1.94-3.6 2.67 2.67 0 0 1 3.18 2.59 2.64 2.64 0 0 1-2.66 2.64Z"
    />
  </svg>
)
