import { PropertiesIconList } from "@geome/react-components-next/lib/components/properties/iconList"
import React, { ReactElement, useMemo } from "react"
import { BPFuelCardsLocation, BPFuelCardsLocatorConfig } from "../../types"
import { useConfig } from "@geome/react-next"
import iconAssets from "../icons/properties"

type IconListProps = {
  location: BPFuelCardsLocation
}

export const IconList = ({ location }: IconListProps): ReactElement => {
  const enabledProperties = useConfig<BPFuelCardsLocatorConfig["locations_list"]["icons"]>(
    "locations_list.icons",
    false
  )

  const properties = useMemo(
    () =>
      enabledProperties.filter((property) => {
        const [group, value] = property.split(".")
        return (
          location[group] && typeof location[group] === "object" && location[group].includes(value)
        )
      }),
    [location, enabledProperties]
  )

  return (
    <PropertiesIconList
      properties={properties}
      icons={iconAssets}
      countryCode={location.country_code}
      maxIcons={8}
    />
  )
}
