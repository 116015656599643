import { CustomProperties } from "@geome/react-next"

export const cssProps = {
  "--primary-colour": "#009201",
  "--text-dark-colour": "#666666",
  "--search-input__text-colour": "#666666",
  "--bp-grey--500": "#666666",
  "--bp-green--500": "#007F00",
  "--bp-green--600": "#006A00",
  "--bp-pale-grey--500": "#EBEBEB",
  "--bp-light-pale-grey--500": "#F7F7F7",
  "--bp-red--500": "#FF0000",
  "--logos-width": "90px",
} as const satisfies CustomProperties
