import { useRecoilValue } from "@geome/recoil"
import React, { ReactElement, Suspense } from "react"
import { truckRouteOptionsAtom } from "../../recoil/atoms"
import { LoggingErrorBoundary } from "@geome/react-next/lib/util/errorBoundary"

const TruckModel = React.lazy(async () =>
  import("@geome/react-components-next/lib/components/truck").then((module) => ({
    default: module.TruckModel,
  }))
)

export const TruckViz = (): ReactElement | null => {
  const truckOptions = useRecoilValue(truckRouteOptionsAtom)
  const { width, height, length } = truckOptions
  if ([width, height, length].some((n) => typeof n !== "number" || isNaN(n))) return null

  return (
    <Suspense>
      <LoggingErrorBoundary>
        <TruckModel
          width={width}
          length={length}
          height={height}
          axles={truckOptions.number_of_axles}
        />
      </LoggingErrorBoundary>
    </Suspense>
  )
}
