import React, { ReactElement } from "react"

export const Header = (): ReactElement => (
  <div className="search__header">
    <svg width="31" height="40" viewBox="0 0 31 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.12029 14.4596C9.19285 13.0771 9.43094 11.7082 9.82948 10.3823C10.9881 11.1421 12.0514 12.0377 12.9967 13.05C13.5377 11.7757 14.2298 10.5707 15.0582 9.46094C15.8869 10.5707 16.5794 11.7757 17.121 13.05C18.0664 12.0378 19.1297 11.1421 20.2883 10.3823C20.605 11.4136 20.9135 12.8603 20.9961 14.4596C22.2311 13.8308 23.5372 13.3521 24.8863 13.0335C24.8312 14.1103 24.626 15.5775 24.1564 17.1067C25.5322 16.9379 26.9233 16.9342 28.3 17.0957C27.88 18.0885 27.1846 19.3963 26.2193 20.6738C27.5699 20.9851 28.8782 21.457 30.1164 22.0792C29.1659 23.0866 28.0979 23.9767 26.9354 24.7305C28.0979 25.4842 29.1658 26.3743 30.1164 27.3817C28.8782 28.0038 27.5698 28.4757 26.2193 28.7871C27.0535 29.8925 27.7521 31.0938 28.3 32.3652C27.23 32.4972 25.7483 32.5495 24.1564 32.3542C24.626 33.8848 24.8312 35.352 24.8863 36.4274C23.5377 36.1088 22.2321 35.63 20.9975 35.0014C20.9248 36.3838 20.6867 37.7527 20.2883 39.0786C19.1297 38.3188 18.0664 37.4232 17.121 36.4109C16.5793 37.6852 15.8868 38.8901 15.0582 40C14.2301 38.8901 13.538 37.6851 12.9967 36.4109C12.0514 37.4232 10.9881 38.3188 9.82948 39.0786C9.43103 37.7531 9.19294 36.3848 9.12029 35.0027C7.69227 35.7302 6.28216 36.1854 5.23009 36.4274C5.28517 35.352 5.49173 33.8848 5.95993 32.3542C4.58458 32.523 3.19395 32.5267 1.81772 32.3652C2.36538 31.0939 3.06347 29.8927 3.89709 28.7871C2.54655 28.4757 1.2382 28.0038 0 27.3817C0.950585 26.3744 2.01852 25.4843 3.18102 24.7305C2.01852 23.9766 0.950585 23.0866 0 22.0792C0.96119 21.5897 2.3355 21.0341 3.89709 20.6752C3.06356 19.5696 2.36547 18.3683 1.81772 17.0971C3.19389 16.935 4.58454 16.9383 5.95993 17.1067C5.55485 15.7834 5.30983 14.4164 5.23009 13.0349C6.57915 13.3529 7.8852 13.8312 9.12029 14.4596Z"
        fill="#009901"
      />
      <path
        d="M6.31721 26.2707C5.21173 25.8901 4.15834 25.3727 3.18164 24.7306C4.15837 24.0889 5.21177 23.5719 6.31721 23.1918C5.40874 22.457 4.59593 21.6116 3.89771 20.6753C5.03509 20.4055 6.20181 20.2789 7.37067 20.2985C6.7687 19.298 6.29452 18.2263 5.95918 17.1082C7.07184 17.2443 8.23684 17.5028 9.35226 17.9415C9.12896 16.7952 9.05031 15.6255 9.11816 14.4597C10.1179 14.9685 11.1245 15.6093 12.0238 16.4013C12.2 15.2187 12.5581 14.0801 12.996 13.0515C13.7982 13.9006 14.4908 14.8467 15.0574 15.8678C15.6303 14.8158 16.3574 13.8697 17.1203 13.0515C17.5568 14.0801 17.9148 15.2174 18.0939 16.4013C18.9765 15.6357 19.9517 14.9834 20.9967 14.4597C21.0646 15.6255 20.9859 16.7952 20.7626 17.9415C21.878 17.5028 23.0444 17.2443 24.1557 17.1068C23.828 18.1767 23.3708 19.2781 22.7442 20.2985C23.9422 20.2682 25.1265 20.4223 26.2185 20.6739C25.5438 21.5677 24.7368 22.4465 23.799 23.1918C24.9351 23.5727 25.9941 24.1227 26.9346 24.7319C25.9941 25.3397 24.9351 25.8898 23.799 26.2693C24.7076 27.004 25.5204 27.8494 26.2185 28.7858C25.1265 29.0389 23.9422 29.1929 22.7442 29.1626C23.3708 30.183 23.828 31.2845 24.1557 32.3543C23.0444 32.2168 21.8794 31.9583 20.7626 31.521C21.0008 32.694 21.0545 33.8849 20.9967 35.0015C19.9517 34.4778 18.9765 33.8255 18.0939 33.0598C17.9148 34.2424 17.5568 35.381 17.1203 36.411C16.3177 35.5618 15.6247 34.6157 15.0574 33.5947C14.4907 34.6157 13.7982 35.5619 12.996 36.411C12.5325 35.3384 12.2055 34.2121 12.0224 33.0584C11.1398 33.8241 10.1646 34.4765 9.11954 35.0001C9.05181 33.8347 9.13045 32.6655 9.35364 31.5196C8.23684 31.9569 7.07184 32.2154 5.95918 32.3529C6.29441 31.2344 6.7686 30.1621 7.37067 29.1613C6.17262 29.1915 4.98835 29.0375 3.89771 28.7858C4.57247 27.892 5.37943 27.0147 6.31721 26.2693V26.2707Z"
        fill="#99CC01"
      />
      <path
        d="M14.0038 18.7555C14.2025 17.7439 14.5584 16.7695 15.0586 15.8677C15.5447 16.7588 15.9192 17.7255 16.1134 18.7555C16.6471 17.8728 17.3157 17.079 18.095 16.4026C18.2451 17.4065 18.2671 18.4419 18.0964 19.476C18.9 18.8288 19.8 18.311 20.7638 17.9414C20.5663 18.9528 20.2118 19.9271 19.7131 20.8292C20.69 20.496 21.7131 20.3174 22.7454 20.2997C22.2136 21.183 21.5469 21.9778 20.7693 22.6554C21.8009 22.6755 22.8231 22.8565 23.7988 23.1917C23.0042 23.8242 22.116 24.3605 21.1356 24.7318C22.0986 25.1025 22.9976 25.6212 23.8002 26.2692C22.8241 26.6047 21.8014 26.7856 20.7693 26.8055C21.547 27.4835 22.2137 28.2788 22.7454 29.1625C21.713 29.1441 20.6899 28.9645 19.7131 28.6303C20.2281 29.5448 20.5627 30.5253 20.7638 31.5195C19.8005 31.1498 18.9009 30.632 18.0978 29.9848C18.2575 31.0036 18.2565 32.0411 18.095 33.0597C17.3161 32.3828 16.6479 31.5884 16.1148 30.7054C15.9156 31.7171 15.5592 32.6915 15.0586 33.5932C14.5584 32.6914 14.2025 31.717 14.0038 30.7054C13.4706 31.5885 12.8025 32.3828 12.0235 33.0597C11.8621 32.0411 11.8612 31.0037 12.0208 29.9848C11.2083 30.649 10.2995 31.1496 9.35479 31.5209C9.55584 30.5266 9.89184 29.5448 10.4041 28.6303C9.42772 28.9643 8.40509 29.1438 7.37319 29.1625C7.90473 28.2787 8.57149 27.4834 9.34928 26.8055C8.31763 26.7854 7.29546 26.6044 6.31974 26.2692C7.12179 25.6209 8.02038 25.1017 8.98298 24.7304C8.01991 24.3593 7.12086 23.8401 6.31836 23.1917C7.2823 22.8699 8.30133 22.6691 9.34928 22.6554C8.57177 21.9777 7.90503 21.1829 7.37319 20.2997C8.40501 20.318 9.42765 20.497 10.4041 20.8305C9.90567 19.928 9.55162 18.9532 9.35479 17.9414C10.2995 18.3127 11.2097 18.8118 12.0208 19.476C11.8614 18.4577 11.8623 17.4207 12.0235 16.4026C12.8022 17.0787 13.4703 17.8721 14.0038 18.7541V18.7555Z"
        fill="#FFFF01"
      />
      <path
        d="M13.9922 21.8013C13.808 20.794 13.8118 19.7613 14.0032 18.7554C14.5044 19.5997 14.8763 20.5486 15.058 21.6129C15.2305 20.6037 15.5881 19.6349 16.1129 18.7554C16.296 19.7193 16.3194 20.7383 16.1266 21.8013C16.6342 20.9123 17.3018 20.1244 18.0958 19.4773C17.9316 20.4878 17.5819 21.4594 17.0644 22.3431C17.8906 21.6445 18.7857 21.155 19.7125 20.8305C19.2305 21.6858 18.5929 22.4806 17.7612 23.1723C18.7228 22.8167 19.742 22.6414 20.7673 22.6553C19.9908 23.3241 19.0973 23.8439 18.1316 24.1885C19.1572 24.1831 20.1749 24.3668 21.1336 24.7303C20.1749 25.0939 19.1572 25.2776 18.1316 25.2722C19.1507 25.6366 20.0223 26.166 20.7673 26.8054C19.742 26.8194 18.7228 26.6441 17.7612 26.2884C18.5504 26.9424 19.212 27.7363 19.7125 28.6302C18.744 28.2933 17.8462 27.7804 17.0644 27.1176C17.6097 28.0513 17.9375 29.0153 18.0972 29.9847C17.3022 29.3379 16.6337 28.5501 16.1252 27.6608C16.318 28.7224 16.2946 29.7413 16.1129 30.7053C15.588 29.8258 15.2304 28.857 15.058 27.8478C14.8855 28.857 14.5279 29.8257 14.0032 30.7053C13.8107 29.6995 13.8061 28.6669 13.9894 27.6594C13.4818 28.5484 12.8142 29.3363 12.0202 29.9834C12.1849 28.9728 12.535 28.0012 13.053 27.1176C12.2268 27.8162 11.3317 28.3057 10.4049 28.6302C10.8855 27.7749 11.5231 26.9801 12.3548 26.2884C11.3932 26.644 10.3741 26.8193 9.34872 26.8054C10.1252 26.1366 11.0188 25.6168 11.9844 25.2722C10.9589 25.2777 9.94115 25.094 8.98242 24.7303C9.94116 24.3667 10.9589 24.1831 11.9844 24.1885C11.0188 23.8438 10.1253 23.324 9.34872 22.6553C10.374 22.6408 11.3932 22.8156 12.3548 23.1709C11.5662 22.5173 10.9051 21.7238 10.4049 20.8305C11.3729 21.1676 12.2702 21.6805 13.0516 22.3431C12.5339 21.459 12.1842 20.4869 12.0202 19.4759C12.8147 20.1229 13.4827 20.9108 13.9908 21.7999"
        fill="#FFFFFE"
      />
      <path
        d="M19.1172 0V7.45325H20.2285V6.73817C20.6857 7.35011 21.1938 7.63201 21.9305 7.63201C23.3021 7.63201 24.277 6.5209 24.277 4.74835C24.277 2.92354 23.2814 1.81381 21.9415 1.81381C21.2048 1.81381 20.6333 2.12459 20.2381 2.7159V0H19.1172ZM21.6716 2.83003C22.553 2.83003 23.1038 3.51485 23.1038 4.75798C23.1038 5.94059 22.553 6.61441 21.6716 6.61441C20.7476 6.61441 20.2175 5.96122 20.2175 4.72772C20.2175 3.4736 20.716 2.83003 21.6716 2.83003ZM27.5848 2.83003C28.4564 2.83003 29.0169 3.51485 29.0169 4.69609C29.0169 5.94059 28.4661 6.61441 27.5848 6.61441C26.6387 6.61441 26.1306 5.98185 26.1306 4.75798C26.1306 3.48322 26.6497 2.83003 27.5834 2.83003H27.5848ZM26.1512 6.7808C26.5354 7.34048 27.1166 7.63339 27.8547 7.63339C29.1932 7.63339 30.1902 6.5209 30.1902 4.69609C30.1902 2.92217 29.2138 1.81381 27.8436 1.81381C27.0959 1.81381 26.5878 2.10396 26.1402 2.75715V1.98982H25.0303V9.63284H26.1512V6.7808Z"
        fill="#009901"
      />
    </svg>
  </div>
)
