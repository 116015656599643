import React, { ReactElement, ReactNode, useContext, useEffect, useRef, useState } from "react"
import { CustomControl } from "@geome/react-components-next/lib/components/map/google/customControl"
import { GoogleMapContext } from "@geome/react-components-next/lib/components/map/google/contexts/map"
import { useI18n } from "@geome/react-next"

export const ViewControls = (): ReactElement => {
  const mapRef = useContext(GoogleMapContext)
  const [mapMode, setMapMode] = useState<"traffic" | "map" | "aerial">("map")
  const trafficLayerRef = useRef<null | google.maps.TrafficLayer>(null)

  const { translate } = useI18n()

  useEffect(() => {
    if (!mapRef) return

    switch (mapMode) {
      case "traffic":
        mapRef.setMapTypeId("roadmap")
        if (!trafficLayerRef.current) {
          trafficLayerRef.current = new google.maps.TrafficLayer()
        }
        trafficLayerRef.current.setMap(mapRef)
        break
      case "map":
        trafficLayerRef.current?.setMap(null)
        mapRef.setMapTypeId("roadmap")
        break
      case "aerial":
        trafficLayerRef.current?.setMap(null)
        mapRef.setMapTypeId("satellite")
        break
    }
    mapRef.setMapTypeId
  }, [mapMode, mapRef])

  return (
    <>
      <CustomControl
        position={google.maps.ControlPosition.TOP_RIGHT}
        wrapped
        onClick={() => setMapMode("map")}
      >
        <CustomControlInner toggled={mapMode === "map"}>
          {translate("map_mode.roadmap")}
        </CustomControlInner>
      </CustomControl>
      <CustomControl
        position={google.maps.ControlPosition.TOP_RIGHT}
        wrapped
        onClick={() => setMapMode("aerial")}
      >
        <CustomControlInner toggled={mapMode === "aerial"}>
          {translate("map_mode.satellite")}
        </CustomControlInner>
      </CustomControl>
      <CustomControl
        position={google.maps.ControlPosition.TOP_RIGHT}
        wrapped
        onClick={() => setMapMode("traffic")}
      >
        <CustomControlInner toggled={mapMode === "traffic"}>
          {translate("map_mode.traffic")}
        </CustomControlInner>
      </CustomControl>
    </>
  )
}

const CustomControlInner = ({
  toggled,
  children,
}: {
  toggled: boolean
  children: ReactNode
}): ReactElement => (
  <div className={`custom-control__inner ${toggled ? "custom-control__inner--toggled" : ""}`}>
    {children}
  </div>
)
