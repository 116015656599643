import { useRecoilState } from "@geome/recoil"
import { directionsOptionsAtom } from "@geome/recoil/dist/feature/directions/atoms"
import { DirectionsOptions } from "@geome/types"

export type ToggleableDirectionsOptions = Pick<DirectionsOptions, "avoid_tolls" | "avoid_highways">

export const useToggleableDirectionsOptions = (): {
  toggleOption: (key: keyof ToggleableDirectionsOptions) => void
  toggleableOptions: ToggleableDirectionsOptions
} => {
  const [options, setOptions] = useRecoilState(directionsOptionsAtom)

  const toggleOption = (key: keyof ToggleableDirectionsOptions): void =>
    setOptions({
      ...options,
      [key]: !options[key],
    })

  const { avoid_tolls, avoid_highways } = options

  return { toggleOption, toggleableOptions: { avoid_tolls, avoid_highways } }
}
