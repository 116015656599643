import { useRecoilCallback } from "@geome/recoil"
import { directionsResultAtom, waypointIdsAtom } from "@geome/recoil/dist/feature/directions/atoms"
import { generateWaypointId } from "@geome/recoil/dist/feature/directions/utils/waypointId"
import { appModeAtom } from "../recoil/atoms"
import { AppModes } from "../types"

export const useChangeAppMode = (): ((newAppMode: AppModes) => void) =>
  useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (newAppMode: AppModes) => {
        const currentAppMode = await snapshot.getPromise(appModeAtom)
        if (currentAppMode === "directions") {
          reset(directionsResultAtom)
          set(waypointIdsAtom, [generateWaypointId(), generateWaypointId()])
        }

        set(appModeAtom, newAppMode)
      },
    []
  )
