import React, { ReactElement } from "react"
import { Search as GenericSearch } from "@geome/react-components-next/lib/components/search/search"
import { MagnifyingGlassIcon } from "../icons/magnifyingGlass"
import { RecentSearches } from "@geome/react-components-next/lib/components/search/recentSearches"
import { Header } from "./header"
import { FiltersButton } from "../filters/button"
import { DirectionsModeButton } from "../directions/modeButton"

export const Search = (): ReactElement => (
  <>
    <Header />
    <div className="search__wrapper">
      <GenericSearch
        completionProps={{
          hasGeolocation: true,
          renderPreSearchContent: (onSelect, atom) => (
            <RecentSearches
              renderIcon={() => null}
              onSelect={onSelect}
              selectionIntentAtom={atom}
            />
          ),
          captureRecentSearches: true,
        }}
        renderSearchIcon={() => <MagnifyingGlassIcon />}
        renderAfter={() => (
          <>
            <DirectionsModeButton />
            <FiltersButton />
          </>
        )}
      />
    </div>
  </>
)
