import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useRecoilValue } from "@geome/recoil"
import { activeRouteOptionsCountSelector } from "@geome/recoil/dist/feature/directions/selectors"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import React, { ReactElement, useMemo } from "react"
import { BPFuelCardsLocatorConfig, BPFuelCardsLocatorModal } from "../../types"
import { RouteOptionsIcon } from "../icons/routeOptions"
import { truckRouteOptionsAtom, vehicleTypeAtom } from "../../recoil/atoms"
import { useConfig } from "@geome/react-next"

export const RouteOptionsButton = (): ReactElement => {
  const { translate } = useI18n()
  const { currentModal, setCurrentModal } = useModalState<BPFuelCardsLocatorModal>()
  const activeCount = useRecoilValue(activeRouteOptionsCountSelector)

  const truckRouteOptions = useRecoilValue(truckRouteOptionsAtom)
  const routeOptionsConfig = useConfig<BPFuelCardsLocatorConfig["route_options"]>(
    "route_options",
    false
  )
  const vehicleType = useRecoilValue(vehicleTypeAtom)

  const fullOptionsCount = useMemo(() => {
    if (vehicleType === "car") return activeCount
    return (
      activeCount +
      Object.entries(truckRouteOptions).reduce((sum, [key, value]) => {
        if (routeOptionsConfig[key].default === value) return sum
        return sum + 1
      }, 0)
    )
  }, [activeCount, routeOptionsConfig, truckRouteOptions, vehicleType])

  const count = fullOptionsCount > 0 ? fullOptionsCount : undefined
  const countDescription = count
    ? (translate("route_options.button.aria_count", { count }) as string)
    : undefined

  return (
    <ButtonWithIcon
      onClick={() => {
        setCurrentModal(currentModal?.name === "RouteOptions" ? null : { name: "RouteOptions" })
      }}
      text={translate("route_options.button.aria_text") as string}
      textPosition="tooltip"
      count={count}
      countDescription={countDescription}
      icon={<RouteOptionsIcon />}
      name="toggle-modal-button"
      className="app__sidebar-route-options-toggle-button"
    />
  )
}
