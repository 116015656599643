import { useLocations } from "@geome/recoil/dist/feature/locations/hooks"
import React, { ReactElement, Suspense } from "react"

import { BPFuelCardsMarker } from "@geome/markers"
import { BPFuelCardsLocation } from "../../types"
import { useIsShowingRoute } from "../../hooks/useIsShowingRoute"

export const Locations = (): ReactElement => {
  const locations = useLocations<BPFuelCardsLocation>({
    resource: "locations",
    endpoint: "within_bounds",
  })

  const alongCorridorLocations = useLocations<BPFuelCardsLocation>({
    resource: "locations",
    endpoint: "along_corridor",
  })

  const showRoute = useIsShowingRoute()

  return (
    <Suspense>
      {(showRoute ? alongCorridorLocations : locations).map((location) => (
        <BPFuelCardsMarker key={location.id} location={location} />
      ))}
    </Suspense>
  )
}
