import { useRecoilValue, useRecoilValueLoadable } from "@geome/recoil"
import { directionsOptionsAtom } from "@geome/recoil/dist/feature/directions/atoms"
import { useAlongCorridorRequest } from "@geome/recoil/dist/feature/locations/hooks"
import { LatLngBounds } from "@geome/types"
import { apiPOSTFiltersSelector } from "@geome/recoil/dist/feature/filters/selectors"
import { currentPTVResponseSelector } from "../../recoil/selectors"
import { useEffect } from "react"

export const useCallAlongCorridorOnChange = (
  makeRequest: ReturnType<typeof useAlongCorridorRequest>,
  actionBounds: LatLngBounds | null
): void => {
  const directionsOptions = useRecoilValue(directionsOptionsAtom)
  const filters = useRecoilValue(apiPOSTFiltersSelector)
  const ptvResponseLoadable = useRecoilValueLoadable(currentPTVResponseSelector)

  useEffect(() => {
    if (ptvResponseLoadable.state !== "hasValue") return
    const ptvResponse = ptvResponseLoadable.contents

    if (
      actionBounds &&
      ptvResponse &&
      ptvResponse.routes &&
      ptvResponse.routes[0].overview_polyline
    ) {
      makeRequest({
        bounds: actionBounds,
        filters,
        polyline: ptvResponse.routes[0].overview_polyline,
        directionsOptions,
      })
    }
  }, [actionBounds, directionsOptions, filters, makeRequest, ptvResponseLoadable])
}
