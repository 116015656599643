import React, { ReactElement } from "react"
import { LocationsList as LocationsListBase } from "@geome/react-components-next/lib/components/locationsList"
import { LocationsListItem } from "@geome/react-components-next/lib/components/locationsList/item"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { ResourceEndpoint } from "@geome/types"
import { OpenNow } from "@geome/react-components-next/lib/components/openNow"
import { useI18n } from "@geome/react-next"
import { BPFuelCardsLocation } from "../../types"
import { IconList } from "./iconList"
import { useRecoilValue } from "@geome/recoil"
import { useChangeAppMode } from "../../hooks/useChangeAppMode"
import { appModeAtom } from "../../recoil/atoms"
import { useDirectionsToLocation } from "@geome/recoil/dist/feature/directions/hooks"

export const LocationsList = ({
  endpoint = "nearest_to",
}: {
  endpoint?: ResourceEndpoint
}): ReactElement => {
  const { translate } = useI18n()
  const appMode = useRecoilValue(appModeAtom)
  const changeAppMode = useChangeAppMode()
  const getDirectionsToLocation = useDirectionsToLocation()

  return (
    <BlockScopingContextProvider block="locations-list">
      <LocationsListBase endpoint={endpoint} resource="locations">
        {(location: BPFuelCardsLocation) => {
          const {
            name,
            formatted_address,
            driving_distance,
            distance,
            open_status,
            next_open_status_change,
          } = location

          const displayDistance = driving_distance ?? distance ?? null

          const onDirectionsClick = (): void => {
            getDirectionsToLocation(location)
            if (appMode !== "directions") changeAppMode("directions")
          }

          return (
            <LocationsListItem
              key={location.id}
              isSplitButton
              resource="locations"
              location={location}
              renderContent={(_, props) => (
                <>
                  <div className="locations-list__container" {...props} tabIndex={0} role="button">
                    <div className="locations-list__item-info-name">{name}</div>
                    <div className="locations-list__content">
                      <div className="locations-list__item-info">
                        <div className="locations-list__address">{formatted_address}</div>
                        {displayDistance && (
                          <div className="locations-list__distance">
                            {translate("distance", {
                              convert: true,
                              distance: displayDistance,
                            })}
                          </div>
                        )}
                      </div>
                      <div className="locations-list__item-open-now">
                        {open_status && (
                          <OpenNow
                            openStatus={open_status}
                            nextOpenStatusChange={next_open_status_change || null}
                            configPath=""
                          />
                        )}
                      </div>
                    </div>
                    <IconList location={location} />
                  </div>
                  <button
                    onClick={onDirectionsClick}
                    className="locations-list__add-to-route-button"
                  >
                    {"Add to route"}
                  </button>
                </>
              )}
            />
          )
        }}
      </LocationsListBase>
    </BlockScopingContextProvider>
  )
}
