import React, { ReactElement } from "react"

export const LoadingBoxes = (): ReactElement => (
  <div className="loading-boxes">
    <div className="loading-boxes__box"></div>
    <div className="loading-boxes__box"></div>
    <div className="loading-boxes__box"></div>
    <div className="loading-boxes__box"></div>
  </div>
)
