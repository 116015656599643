import { useRecoilState } from "@geome/recoil"
import { directionsOptionsAtom } from "@geome/recoil/dist/feature/directions/atoms"
import { CorridorRadiusOptions } from "@geome/types"

export const useRadiusDirectionsOption = (): {
  radius: number
  setRadius: (value: number) => void
  options: Omit<CorridorRadiusOptions, "value">
} => {
  const [{ corridor_radius, ...allOptions }, setOptions] = useRecoilState(directionsOptionsAtom)
  const { value, ...options } = corridor_radius

  return {
    radius: value,
    setRadius: (value: number) =>
      setOptions({ ...allOptions, corridor_radius: { ...corridor_radius, value } }),
    options,
  }
}
