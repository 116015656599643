import { useI18n } from "@geome/react-next"
import React, { ReactElement, useMemo } from "react"
import { useMobileMapListState } from "@geome/recoil/dist/feature/ui/hooks"
import { useIsShowingMapClusters } from "@geome/recoil/dist/feature/locations/hooks"
import { useIsMobile } from "../../hooks/useIsMobile"
import { useViewSwitcher } from "../app/sidebar/useViewSwitcher"
import { useRecoilValue } from "@geome/recoil"
import { appModeAtom } from "../../recoil/atoms"
import { CustomControl } from "@geome/react-components-next/lib/components/map/google/customControl"

export const MobileListButton = (): ReactElement | null => {
  const { translate } = useI18n()
  const isShowingClusters = useIsShowingMapClusters({ resource: "locations" })
  const { mobileView, setMobileView } = useMobileMapListState()
  const isMobile = useIsMobile()
  const appMode = useRecoilValue(appModeAtom)
  const { showInfoWindow, showFilters, showItinerary } = useViewSwitcher()

  const shouldShowButton = useMemo(
    () =>
      !isShowingClusters &&
      !showInfoWindow &&
      !showFilters &&
      !showItinerary &&
      isMobile &&
      appMode === "search",
    [isShowingClusters, isMobile, showInfoWindow, showFilters, showItinerary, appMode]
  )

  const handleClick = (): void => setMobileView(mobileView === "list" ? "map" : "list")

  if (!shouldShowButton) return null

  return (
    <CustomControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
      <button className="locations-list__show-button" onClick={handleClick}>
        {mobileView === "map" && translate("locations_list.show_list_mobile_button")}
        {mobileView === "list" && translate("locations_list.show_map_mobile_button")}
      </button>
    </CustomControl>
  )
}
