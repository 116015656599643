import { useI18n } from "@geome/react-next"
import React, { ReactElement, useMemo } from "react"
import { useRecoilState, useRecoilValue } from "@geome/recoil"
import { appModeAtom, directionsViewAtom } from "../../recoil/atoms"
import { useIsMobile } from "../../hooks/useIsMobile"
import { CustomControl } from "@geome/react-components-next/lib/components/map/google/customControl"
import { useViewSwitcher } from "../app/sidebar/useViewSwitcher"
import { currentPTVResponseSelector } from "../../recoil/selectors"

export const MobileItineraryButton = (): ReactElement | null => {
  const { translate } = useI18n()
  const { showInfoWindow, showRouteOptions } = useViewSwitcher()
  const appMode = useRecoilValue(appModeAtom)
  const [directionsView, setDirectionsView] = useRecoilState(directionsViewAtom)
  const isMobile = useIsMobile()

  const ptvResult = useRecoilValue(currentPTVResponseSelector)
  const hasRoute = ptvResult?.routes && ptvResult.routes[0]

  const shouldShowButton = useMemo(
    () => appMode === "directions" && isMobile && hasRoute && !showInfoWindow && !showRouteOptions,
    [appMode, isMobile, hasRoute, showInfoWindow, showRouteOptions]
  )

  const handleClick = (): void =>
    setDirectionsView(directionsView === "itinerary" ? "map" : "itinerary")

  if (!shouldShowButton) return null

  return (
    <CustomControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
      <button className="directions__show-button" onClick={handleClick}>
        {directionsView === "map" && translate("directions.show_itinerary_mobile_button")}
        {directionsView === "itinerary" && translate("directions.show_map_mobile_button")}
      </button>
    </CustomControl>
  )
}
