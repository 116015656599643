import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import React, { ReactElement } from "react"
import { DirectionsModeIcon } from "../icons/directionsMode"
import { useChangeAppMode } from "../../hooks/useChangeAppMode"
import { useSelectedPlaceAsDestination } from "@geome/recoil/dist/feature/directions/hooks"
import { usePublishUserInteraction } from "@geome/react-next"

export const DirectionsModeButton = (): ReactElement => {
  const { translate } = useI18n()

  const changeAppMode = useChangeAppMode()
  const setDirectionsToPlace = useSelectedPlaceAsDestination()
  const [publishInteraction] = usePublishUserInteraction("directions-link", "click")

  return (
    <ButtonWithIcon
      className="app__sidebar-directions-mode-button"
      onClick={() => {
        setDirectionsToPlace()
        changeAppMode("directions")
        publishInteraction()
      }}
      text={translate("directions.button.aria_text") as string}
      textPosition="tooltip"
      icon={<DirectionsModeIcon />}
      name="toggle-directions-button"
    />
  )
}
