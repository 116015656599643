import React, { ReactElement, useMemo } from "react"
import { WaypointsSearch } from "@geome/react-components-next/lib/components/search/waypoints"
import { MagnifyingGlassIcon } from "../icons/magnifyingGlass"
import { RecentSearches } from "@geome/react-components-next/lib/components/search/recentSearches"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { ChevronIcon } from "@geome/react-components-next/lib/components/icons/chevron"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useChangeAppMode } from "../../hooks/useChangeAppMode"
import { usePublishUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { BPFuelCardsCompletion } from "../../types"
import { FiltersButton } from "../filters/button"
import { TruckIcon } from "../icons/truckIcon"
import { CarIcon } from "../icons/carIcon"
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
} from "@geome/recoil"
import { currentPTVRequestBodyAtom, vehicleTypeAtom } from "../../recoil/atoms"
import { PTVHasErroredSelector, PTVRequestOptionsSelector } from "../../recoil/selectors"
import { waypointSelectionIntentSelector } from "@geome/recoil/dist/feature/directions/selectors"

import { PlaceDetailsAtomFamily } from "@geome/recoil/dist/feature/places/atoms"
import { LocationsPoolAtomFamily } from "@geome/recoil/dist/feature/locations/atoms"
import { RouteOptionsButton } from "../routeOptions/button"

export const DirectionsSearch = (): ReactElement => {
  const setAppMode = useChangeAppMode()
  const [publishSearchClick] = usePublishUserInteraction("search-button", "click")
  const { translate } = useI18n()

  const [vehicleType, setVehicleType] = useRecoilState(vehicleTypeAtom)

  const clearPTVRequest = useResetRecoilState(currentPTVRequestBodyAtom)
  const ptvHasErrored = useRecoilValueLoadable(PTVHasErroredSelector)

  const makeDirectionsRequest = useRecoilCallback(({ snapshot, set }) => async () => {
    const options = await snapshot.getPromise(PTVRequestOptionsSelector)
    const waypointSelectionItents = await snapshot.getPromise(waypointSelectionIntentSelector)

    const encodedWaypoints = await Promise.all(
      waypointSelectionItents.map(async (si) => {
        if (si?.type === "google_place" && si.place_id) {
          return snapshot.getPromise(PlaceDetailsAtomFamily(si.place_id)).then((place) => {
            if (!place) throw new Error(`Failed to get place for place id "${si.place_id}"`)
            return `${place.lat},${place.lng}`
          })
        }

        if (si?.type === "location") {
          return snapshot
            .getPromise(LocationsPoolAtomFamily({ resource: si.resource }))
            .then((pool) => {
              const location = pool[si.id]
              if (!location) throw new Error(`Failed to get location for id "${si.id}"`)

              const { lat, lng } = location
              return `${lat},${lng}`
            })
        }
      })
    )

    const [origin, ...restOne] = encodedWaypoints
    const [destination, ...restTwo] = restOne.reverse()
    const waypoints = restTwo.reverse().map((w) => ({ location: w, stopover: true }))

    set(currentPTVRequestBodyAtom, { waypoints, origin, destination, options })
  })

  return (
    <BlockScopingContextProvider block="directions-search">
      <CSSCustomPropertyContextProvider properties={{ "--error-colour": "#FF0000" }}>
        <div className="search__wrapper">
          <button
            className="search__back-to-search-button"
            onClick={() => {
              setAppMode("search")
              clearPTVRequest()
              publishSearchClick()
            }}
          >
            <ChevronIcon strokeWidth={3} direction="left" />
            {translate("directions.buttons.back_to_search")}
          </button>
          <div className="search__vehicle-type">
            <label className="search__vehicle-type-option">
              <TruckIcon />
              {translate("directions.vehicle_type.truck")}
              <input
                type="radio"
                name="vehicle-type"
                checked={vehicleType === "truck"}
                onChange={() => setVehicleType("truck")}
              ></input>
            </label>

            <label className="search__vehicle-type-option">
              <CarIcon />
              {translate("directions.vehicle_type.car")}
              <input
                type="radio"
                name="vehicle-type"
                checked={vehicleType === "car"}
                onChange={() => setVehicleType("car")}
              ></input>
            </label>
          </div>
          <WaypointsSearch
            showError={ptvHasErrored.state === "hasValue" && ptvHasErrored.contents}
            completionProps={{
              hasGeolocation: true,
              captureRecentSearches: false,
              renderPreSearchContent: (onSelect, atom) => (
                <RecentSearches
                  onSelect={onSelect}
                  selectionIntentAtom={atom}
                  renderIcon={() => null}
                />
              ),
            }}
            renderCompletions={{
              completions: {
                google: ({ description }) => <span>{description}</span>,
                locations: (location: BPFuelCardsCompletion) => <span>{location.value}</span>,
              },
            }}
            renderSearchIcon={() => <MagnifyingGlassIcon />}
          />
          <div className="directions__buttons">
            <RouteOptionsButton />
            <FiltersButton />
          </div>

          <GenerateRouteButton onClick={makeDirectionsRequest} />
        </div>
      </CSSCustomPropertyContextProvider>
    </BlockScopingContextProvider>
  )
}

type GenerateRouteButtonProps = {
  onClick: () => void
}

const GenerateRouteButton = ({ onClick }: GenerateRouteButtonProps): ReactElement => {
  const waypoints = useRecoilValue(waypointSelectionIntentSelector)
  const { translate } = useI18n()

  const disabled = useMemo(() => {
    if (waypoints.length < 2) return true
    if (waypoints.some((w) => w === null)) return true
    return false
  }, [waypoints])

  return (
    <button className="directions__route-button" onClick={onClick} disabled={disabled}>
      {translate("directions.generate_route")}
    </button>
  )
}
