import fallbackIcon from "../../../images/default_icon.svg"

const iconAssets = {}

const removeExtensionFromFileName = (fileName: string): string =>
  fileName
    .split(".")
    .filter((_, index, array) => index !== array.length - 1)
    .join(".")

const formatFileNameToKey = (filename: string): string => {
  const fileNameWithoutRelativeSymbol = filename.replace("./", "")
  const fileNameWithoutExtension = removeExtensionFromFileName(fileNameWithoutRelativeSymbol)
  return fileNameWithoutExtension
}

if (import.meta.webpackContext) {
  const iconFiles = import.meta.webpackContext("../../../images/propertyIcons/", {
    recursive: false,
    mode: "sync",
  })

  iconFiles
    .keys()
    .forEach((fileName) => (iconAssets[formatFileNameToKey(fileName)] = iconFiles(fileName)))
}

export default iconAssets

export const getImageForProperty = (property: string): string => {
  const key = property.replace(".", "_")
  if (iconAssets[key]) return iconAssets[key]
  return fallbackIcon
}
