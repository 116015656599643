import { DirectionsItinerary } from "@geome/react-components-next/lib/components/directionsItinerary"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useRecoilValue } from "@geome/recoil"
import React, { ReactElement } from "react"
import { currentPTVResponseSelector } from "../../recoil/selectors"
import { ManeuverIcon } from "./maneuverIcon"

export const Itinerary = (): ReactElement | null => {
  const { translate } = useI18n()

  const ptvResult = useRecoilValue(currentPTVResponseSelector)

  if (!ptvResult?.routes || !ptvResult.routes[0]) return null
  return (
    <DirectionsItinerary
      route={ptvResult.routes[0] as any}
      shouldRenderLegDestination
      renderTotals={({ content }) => <div className="itinerary__top-info">{content}</div>}
      renderStep={({ content, step }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexBasis: "30px" }}>
            <ManeuverIcon maneuver={step.maneuver} />
          </div>
          {content}
        </div>
      )}
      renderLeg={({ stepContent, leg }) => (
        <div className="itinerary__leg">
          {stepContent}
          <div className="itinerary__leg-destination">
            <span>
              {translate("directions_itinerary.leg_destination", { placeName: leg.end_address })}
            </span>
          </div>
        </div>
      )}
    />
  )
}
