import React, { ReactElement } from "react"
import { useIsMobile } from "../../hooks/useIsMobile"
import { OptionsSetter } from "@geome/react-components-next/lib/components/map/google/optionsSetter"

export const MapOptions = (): ReactElement => {
  const isMobile = useIsMobile()

  if (isMobile) {
    return <OptionsSetter mapTypeControl={false} zoomControl={false} />
  } else {
    return <OptionsSetter mapTypeControl={false} zoomControl />
  }
}
