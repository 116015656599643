import React, { ReactElement, useCallback } from "react"
import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { ScrollWithHeaderFooter } from "@geome/react-components-next/lib/components/scrollWithHeaderFooter"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import { CheckboxControl } from "@geome/react-components-next/lib/components/checkboxControl"
import { SliderInput } from "@geome/react-components-next/lib/components/sliderInput"
import { CloseIcon } from "../icons/close"
import {
  ToggleableDirectionsOptions,
  useToggleableDirectionsOptions,
} from "./useToggleableDirectionsOptions"
import { useSubscribeToUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { useRadiusDirectionsOption } from "./useRadiusDirectionsOption"
import { useConfig } from "@geome/react-next/lib/hooks/config"
import { DistanceUnit } from "@geome/intl"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { useRecoilState, useRecoilValue } from "@geome/recoil"
import { truckRouteOptionsAtom, vehicleTypeAtom } from "../../recoil/atoms"
import { TruckViz } from "./truck"

export const RouteOptions = (): ReactElement => {
  const { toggleableOptions, toggleOption } = useToggleableDirectionsOptions()
  const { radius, setRadius, options } = useRadiusDirectionsOption()

  const { setCurrentModal } = useModalState()
  const { translate } = useI18n()

  const closeRouteOptions = useCallback(async () => setCurrentModal(null), [setCurrentModal])

  useSubscribeToUserInteraction("*,-route-options:*:*,-*:toggle-modal-button:*", closeRouteOptions)

  const unit = useConfig<DistanceUnit>("intlData.formats.number.distance.unit", true)

  const vehicleType = useRecoilValue(vehicleTypeAtom)

  return (
    <BlockScopingContextProvider block="route-options">
      <ScrollWithHeaderFooter
        className="route-options"
        renderHeader={() => (
          <div className="route-options__title-bar">
            <h3>{translate("route_options.title")}</h3>
            <ButtonWithIcon
              className="route-options__close-button"
              text={translate("route_options.close") as string}
              textPosition="tooltip"
              icon={<CloseIcon />}
              onClick={closeRouteOptions}
              name="close-button"
            />
          </div>
        )}
      >
        {Object.entries(toggleableOptions).map(
          ([key, checked]: [keyof ToggleableDirectionsOptions, boolean]) => (
            <CheckboxControl
              key={key}
              id={key}
              className={`route-options__option route-options__option--${checked ? "active" : ""}`}
              style="button"
              checked={checked}
              onCheck={() => toggleOption(key)}
            >
              {translate(`route_options.options.${key}`)}
            </CheckboxControl>
          )
        )}

        <div className="route-options__radius-input">
          <p>{translate("route_options.range_description", { unit })}</p>
          <SliderInput
            value={radius}
            min={options.range?.min ?? 1}
            max={options.range?.max ?? 25}
            step={options.steps}
            onChange={setRadius}
            valueTranslationToken="route_options.radius.value"
            labelTranslationToken="route_options.radius.label"
            unit={unit}
            delay={250}
          />
        </div>
        {vehicleType === "truck" && <TruckOptions />}
      </ScrollWithHeaderFooter>
    </BlockScopingContextProvider>
  )
}

const TruckOptions = (): ReactElement => (
  <>
    <RouteOptionSelect name="emissions_class" />
    <RouteOptionSelect name="hazard_class" />
    <RouteOptionNumber name="weight" />
    <TruckViz />
    <RouteOptionSelect name="number_of_axles" />
    <RouteOptionNumber name="height" />
    <RouteOptionNumber name="length" />
    <RouteOptionNumber name="width" />
  </>
)

const RouteOptionSelect = ({ name }: { name: string }): ReactElement => {
  const { translate } = useI18n()

  const options = useConfig<(string | number)[]>(`route_options.${name}.options`, false)
  const defaultValue = useConfig<string | number>(`route_options.${name}.default`, false)
  const [routeOptions, setRouteOptions] = useRecoilState(truckRouteOptionsAtom)
  const isNumber = typeof defaultValue === "number"

  return (
    <label className="route-options__select">
      {translate(`route_options.options.${name}`)}

      <select
        value={routeOptions[name]}
        className="route-options__select-input"
        onChange={({ target }) =>
          setRouteOptions((curr) => ({
            ...curr,
            [name]: isNumber ? parseFloat(target.value) : target.value,
          }))
        }
      >
        {options.map((value) => (
          <option value={value} key={value}>
            {value}
          </option>
        ))}
      </select>
    </label>
  )
}

const RouteOptionNumber = ({ name }: { name: string }): ReactElement => {
  const { translate } = useI18n()
  const step = useConfig<number>(`route_options.${name}.step`, false)

  const [routeOptions, setRouteOptions] = useRecoilState(truckRouteOptionsAtom)

  return (
    <label className="route-options__number">
      {translate(`route_options.options.${name}`)}

      <input
        className="route-options__number-input"
        type="number"
        step={step}
        value={routeOptions[name]}
        onChange={({ target }) =>
          setRouteOptions((curr) => ({ ...curr, [name]: Math.max(0, parseFloat(target.value)) }))
        }
      />
    </label>
  )
}
