import { useMemo, useState } from "react"
import { useRecoilValue } from "@geome/recoil"
import type { ResourceName } from "@geome/types"
import { AutocompleteSession, GoogleAutocomplete, GooglePlaceDetails } from "@geome/google-apis"
import { useAPIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import { useConfig } from "@geome/react-next/lib/hooks/config"
import { actionBoundsAtom } from "@geome/recoil/dist/feature/map/atoms"
import {
  useCallCompletionsOnChange,
  useCompletionsRequest,
} from "@geome/recoil/dist/feature/search/hooks"

import {
  useAlongCorridorRequest,
  useCallNearestToOnChange,
  useCallWithinBoundsOnChange,
  useNearestToRequests,
  useWithinBoundsRequest,
} from "@geome/recoil/dist/feature/locations/hooks"
import {
  useCallSelectedOnChange,
  useSelectedRequest,
} from "@geome/recoil/dist/feature/selection/hooks"
import { useGeospatialAPIRequester } from "@geome/geospatial-api-requester"

import { useCallDirectionsCompletionsOnChange } from "@geome/recoil/dist/feature/directions/hooks"
import { BPFuelCardsLocatorConfig } from "../../types"
import { useCallAlongCorridorOnChange } from "./alongCorridor"

export const Resources = (): null => {
  const actionBounds = useRecoilValue(actionBoundsAtom)
  const filters = useAPIFilters()
  const config = useConfig<BPFuelCardsLocatorConfig>("", false)
  const [autocompleteSession] = useState(() => new AutocompleteSession())
  const [googleAutocomplete] = useState(
    () => new GoogleAutocomplete(autocompleteSession, config.completions?.options)
  )
  const apiRequester = useGeospatialAPIRequester()

  const enabledResources: ResourceName[] = useMemo(
    () =>
      Object.entries(config.resources)
        .filter(([, { enabled }]) => enabled)
        .map(([resource]) => resource as ResourceName),
    [config]
  )

  const makeWithinBoundsRequests = useWithinBoundsRequest({
    enabledResources,
    config,
    apiRequester,
  })

  const makeNearestToRequests = useNearestToRequests({
    enabledResources,
    config,
    apiRequester,
    limit: 50,
  })

  const makeCompletionsRequestsDebounced = useCompletionsRequest({
    config,
    enabledResources,
    googleAutocomplete,
    actionBounds,
    apiRequester,
  })

  const [placesDetails] = useState(() => new GooglePlaceDetails(autocompleteSession))

  const makeSelectedRequest = useSelectedRequest({
    placesDetails,
    apiRequester,
    filters,
    config,
  })

  const makeAlongCorridorLocationsRequest = useAlongCorridorRequest({
    enabledResources,
    config,
    apiRequester,
  })

  useCallWithinBoundsOnChange(makeWithinBoundsRequests, actionBounds, filters)
  useCallNearestToOnChange(makeNearestToRequests, filters)
  useCallSelectedOnChange(makeSelectedRequest)
  useCallCompletionsOnChange(makeCompletionsRequestsDebounced, filters)

  useCallDirectionsCompletionsOnChange(makeCompletionsRequestsDebounced, filters)
  useCallAlongCorridorOnChange(makeAlongCorridorLocationsRequest, actionBounds)

  return null
}
