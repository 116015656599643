import { langCodeToCountryCode } from "../../utils/langCodeToCountryCode"

const DEFAULT_LANGUAGE = "en"

export const handleLocaleParams = (p: Record<string, string>) => {
  const countryCode = p.countrycode ?? p.country_code ?? p.countryCode ?? p.country ?? null
  const language = p.language ?? null

  if (!countryCode && !language) return p

  const localeLanguage = p.locale?.split("_")[0]
  const localeCountryCode = p.locale?.split("_")[1]

  const langBasedCountryCode = langCodeToCountryCode(language ?? localeLanguage ?? DEFAULT_LANGUAGE)

  const finalLanguage = language ?? localeLanguage ?? DEFAULT_LANGUAGE
  const finalCountryCode = countryCode ?? localeCountryCode ?? langBasedCountryCode

  const finalLocaleString = `${finalLanguage.toLowerCase()}_${finalCountryCode.toUpperCase()}`

  return { ...p, locale: finalLocaleString }
}
